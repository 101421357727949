import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HeaderAndLayout from '../layouts/HeaderAndLayout';
import Dialog from "../DialogBox/DialogBox";
import BeatLoader from 'react-spinners/BeatLoader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CsvBuilder } from 'filefy';

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
    button: {
        textAlign: "center",
        backgroundColor: "#4094F7",
        color: "#FFFFFF",
        borderRadius: "0.5rem",
        marginLeft: "29.8rem",
        marginBottom: "1rem"
    },
});

export default function DenseTable() {
    const classes = useStyles();
    const [product, setProduct] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [callingBackend, setCallingBackend] = useState(false);
    const [succesMessage, setSuccesMessage] = useState(false);
    const [failMessage, setFailMessage] = useState(false);
    const [productMessage, setProductMessage] = useState(false);
    const [item, setItem] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [category, setCategory] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [exportData, setExportData] = useState([]);

    const notify = () => succesMessage ? toast.success('Action successful!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }) : failMessage ? toast.error('Action failed!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }) : productMessage ? toast.warn('Product already exists!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }) : null

    const handleClickOpen = (row) => {
        setItem(row);
        setOpen(true);
        setInitialValues(row);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        loadData();
        loadCategory();
    }, []);

    const loadData = async () => {
        try {
            setCallingBackend(true);
            const { data } = await axios.get("/listing/all/,/,");
            setProduct(data);
            setFilteredItems(data);
            setCallingBackend(false);
        } catch (error) {
            setFailMessage(true);
        }
    };
    const loadCategory = async () => {
        try {
            const { data } = await axios.get("/category");
            setCategory(data);
        } catch (error) {
            console.log("category loading fail")
        }
    };
    const deleteData = async (row) => {
        try {
            const result = await axios.delete(`/listing/${row._id}`);
            if (result.status === 200) {
                setSuccesMessage(true);
                loadData();
            }
        } catch (error) {
            setFailMessage(true);
        }
    };
    useEffect(() => {
        if (succesMessage) {
            notify();
            setSuccesMessage(false);
        } else {
            if (failMessage) {
                notify();
                setFailMessage(false);
            } else {
                if (productMessage) {
                    notify();
                    setProductMessage(false);
                }
            }
        }
    }, [succesMessage, failMessage, productMessage]);

    const onSearch = (e) => {
        const text = String(e.target.value).toLowerCase();
        if (text) {
            const result = product.filter((item) => {
                const str = JSON.stringify(item.title).toLowerCase();

                if (str.search(text) >= 0) return item;
            });
            setFilteredItems(result);
        } else {
            setFilteredItems(product);
        }
    };

    const loadDataBycategoryId = async () => {
        try {
            setCallingBackend(true);
            const { data } = await axios.get(`/listing/all/,/,?categories=${categoryId}`);
            setProduct(data);
            setFilteredItems(data);
            setCategoryId("");
            setCallingBackend(false);
        } catch (error) {
            setFailMessage(true);
        }
    };

    useEffect(() => {
        if (categoryId) {
            if (categoryId) {
                loadDataBycategoryId();
            } else {
                alert("No data found");
            }
        }
        if (filteredItems) {
            let data = [];
            for (let i = 0; i < filteredItems.length; i++) {
                let arrays = [`${filteredItems[i].title}`, filteredItems[i].description, `${filteredItems[i].condition}`, `${filteredItems[i].deliveryMethod}`, `${filteredItems[i].price}`]
                data.push(arrays);
            }
            if (data.length === filteredItems.length) {
                setExportData(data);
            }
        }
    }, [categoryId, filteredItems]);

    const csvBuilder = () => {
        new CsvBuilder("listing_list.csv")
            .setColumns(["title", "description", "condition", "delivery Method", "price"])
            .addRows(exportData)
            .exportFile();
    }

    return (
        <Grid style={{ marginLeft: "16rem", marginTop: "1.5rem" }}>
            <HeaderAndLayout activeItem={"listing"} />
            <Grid item xs={6} md={6}>
                <h1 style={{ marginBottom: "1rem" }}> Listing</h1>
            </Grid>
            <form className="search">
                <div className="input-group" style={{ flexBasis: "100%", paddingBottom: "1rem" }}>
                    <input
                        id="search"
                        name="search"
                        type="text"
                        className="form-control"
                        placeholder="Filter by listing title.."
                        onChange={onSearch}
                        required
                        style={{ fontSize: "1rem", padding: "0.3rem" }}
                    />
                    <label className="visually-hidden" htmlFor="search"></label>
                    <Button onClick={csvBuilder} style={{ marginLeft: "1rem", backgroundColor: "#EBDDE2" }}>
                        Export to CSV
                    </Button>
                </div>
            </form>
            <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell >Description</TableCell>
                                <TableCell align="center">Image</TableCell>
                                <TableCell align="center">Condition</TableCell>
                                <TableCell align="center">Delivery method</TableCell>
                                <TableCell align="">Price</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            callingBackend ? <BeatLoader /> :
                                <TableBody>
                                    {filteredItems.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">{row.title}</TableCell>
                                            <TableCell>
                                                {row.description}
                                            </TableCell>
                                            <TableCell align="center"><img height={70} width={100} src={`${row.images?.length > 0 && row.images[0]}`} /></TableCell>
                                            <TableCell align="center">{row.condition}</TableCell>
                                            <TableCell align="center">{row.deliveryMethod}</TableCell>
                                            <TableCell>{row.price}</TableCell>
                                            <TableCell align="center">
                                                <Button onClick={() => handleClickOpen(row)} variant="contained" style={{ backgroundColor: "#808080" }}>Edit</Button>
                                                <Button onClick={() => deleteData(row)} variant="contained" style={{ backgroundColor: "#ff1a1a" }}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <Dialog
                                        handleClickOpen={handleClickOpen}
                                        open={open}
                                        handleClose={handleClose}
                                        loadData={loadData}
                                        initialValues={initialValues}
                                        item={item}
                                        setSuccesMessage={setSuccesMessage}
                                        setFailMessage={setFailMessage}
                                        setProductMessage={setProductMessage}
                                    />
                                </TableBody>
                        }
                    </Table>
                </TableContainer>
            </Grid>
            <ToastContainer position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHove />
        </Grid>
    );
}

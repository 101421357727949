import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploading from 'react-images-uploading';
import BeatLoader from 'react-spinners/BeatLoader';

export default function ResponsiveDialog(props) {
  const [componentType, setComponentType] = useState("create");
  const [callingBackend, setCallingBackend] = useState(false);
  const { open, handleClose, loadData } = props;
  const [category, setCategory] = useState([]);
  const [image, setImage] = useState(props.initialValues?.images ? props.initialValues.images : []);
  const [finsihed, setFinished] = useState(false);
  const [finalValues, setFinalValues] = useState(null);

  const [initialValues, setInitialValues] = useState({
    title: props.initialValues?.title || "",
    description: props.initialValues?.description || "",
    categories: props.initialValues?.categories || "",
    condition: props.initialValues?.condition || "",
    deliveryMethod: props.initialValues?.deliveryMethod || "",
    price: props.initialValues?.price || "",
    priceRequirements: props.initialValues?.priceRequirements || "",
    images: props.initialValues?.images || [],
  });
  let validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required!")
      .max(35, "Max length for the name is 35")
      .min(2, "Min length for the name is 2"),
    description: Yup.string()
      .required("Description is required!")
      .max(200, "Max length for the Description is 200")
      .min(10, "Min length for the Description is 10"),
    price: Yup.number()
      .required("Price is required!")
  });

  useEffect(() => {
    loadCategory();
    if (props.initialValues) {
      setComponentType("update")
      setValues()
    }
  }, [props.initialValues]);

  const setValues = () => {
    setInitialValues(props.initialValues);
    setImage(props.initialValues?.images);
  }

  const loadCategory = async () => {
    try {
      const { data } = await axios.get("/category");
      setCategory(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
    }
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const handleInput = (e) => {
    setInitialValues((pre) => ({
      ...pre,
      [e.target.name]: e.target.value
    }));
  };

  const publishProduct = () => {
    if (finalValues.images.length >= 1) {
      axios.put(`/listing/${props.initialValues._id}`, finalValues).then((res) => {
        setInitialValues({ "title": "", "description": "", "categories": "", "condition": "", "deliveryMethod": "", "price": "", "images": [], "priceRequirements": "" })
        setFinalValues(null)
        setCallingBackend(false);
        handleClose();
        loadData();
        props.setSuccesMessage(true);
      })
        .catch((error) => {
          props.setFailMessage(true);
        })
    } else {
      setCallingBackend(false);
    }
  }

  const submit = async (cb) => {
    let imagesToUpload = image.filter(img => img?.file)
    if (imagesToUpload?.length > 0) {
      let imageArray = [];
      for (let i = 0; i < imagesToUpload.length; i++) {
        setCallingBackend(true);
        const formData = new FormData();
        formData.append("file", imagesToUpload[i]?.file);
        formData.append("upload_preset", "eywnofyh");
        formData.append("cloud_name", "dtku2ojpc");

        fetch("https://api.cloudinary.com/v1_1/dtku2ojpc/image/upload", {
          method: "post",
          body: formData
        })
          .then(res => res.json()
            .then(formData => {
              imageArray.push(formData.url);
              if (i === imagesToUpload.length - 1) {
                setFinalValues({ ...initialValues, images: [...initialValues.images, ...imageArray] });
              }
            }))
          .catch(err => {
            console.log(err);
          })
      }
    }
    else
      setFinalValues({ ...initialValues })
  };
  useEffect(() => {
    if (finalValues) {
      setCallingBackend(false);
      setFinished(true);
    }
  }, [finalValues]);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    green: {
      backgroundColor: "green",
      color: "white",
    },
    red: {
      backgroundColor: "red",
      color: "white",
    },
    yellow: {
      backgroundColor: "orange",
      color: "white",
    },
    textCenter: {
      textAlign: "center",
    },
    loading: {
      marginRight: "5rem",
    },
  }));

  const maxNumber = 69;
  const onChange = (imageList) => {
    setImage(imageList);
  };

  useEffect(() => {
    if (finsihed)
      publishProduct()
    setFinished(false)

  }, [finsihed]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ errors, isValid, dirty, handleChange, values, setFieldValue, getFieldProps }) => {
          return (
            <Form>
              <Dialog
                fullWidth="100%"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {componentType === "create" && (
                    "Add a new listing "
                  )}
                  {componentType === "update" && (
                    "Edit a listing "
                  )}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} >
                    <Grid item md={6}>
                      <Grid>
                        <Field
                          {...getFieldProps('title')}
                          onChange={handleInput}
                          name="title"
                          label="title"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          multiline
                        ></Field>
                      </Grid>
                      <Grid style={{ marginTop: "1rem" }}>
                        <Field
                          onChange={handleInput}
                          name="description"
                          label="Description"
                          component={TextField}
                          rows="6"
                          variant="outlined"
                          fullWidth
                          multiline
                        ></Field>
                      </Grid>
                      {/* <Grid style={{ marginTop: "1rem" }}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            Categories
                          </InputLabel>
                          <Select
                            onChange={handleInput}
                            value={initialValues.categories ? initialValues.categories : ""}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Category Type"
                            name="categoryId"
                          >
                            {category.length > 0 ? category.map(item => {
                              return <MenuItem value={item.name}>{item.name}</MenuItem>
                            }) : null};
                          </Select>
                        </FormControl>
                      </Grid> */}
                      <Grid style={{ marginTop: "1rem" }}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            Condition
                          </InputLabel>
                          <Select
                            onChange={handleInput}
                            value={initialValues.condition ? initialValues.condition : ""}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Condition Type"
                            name="condition"
                          >
                            <MenuItem value={"New"}>New</MenuItem>
                            <MenuItem value={"Good"}>Good</MenuItem>
                            <MenuItem value={"Used"}>Used</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginTop: "1rem" }}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            Delivery Methods
                          </InputLabel>
                          <Select
                            onChange={handleInput}
                            value={initialValues.deliveryMethod ? initialValues.deliveryMethod : ""}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Delivery Methods"
                            name="deliveryMethod"
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            <MenuItem value={"Local Pickup"}>Local Pickup</MenuItem>
                            <MenuItem value={"Delivery Only"}>Delivery Only</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginTop: "1rem" }}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            Price Requirements
                          </InputLabel>
                          <Select
                            onChange={handleInput}
                            value={initialValues.priceRequirements ? initialValues.priceRequirements : ""}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Price Requirements"
                            name="priceRequirements"
                          >
                            <MenuItem value={"Free"}>Free</MenuItem>
                            <MenuItem value={"Trade"}>Trade</MenuItem>
                            <MenuItem value={"None"}>None</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginTop: "1rem" }}>
                        <Field
                          onChange={handleInput}
                          name="price"
                          label="Price"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          multiline
                        ></Field>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <ImageUploading
                        multiple
                        value={image}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        name="images"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <button
                              style={isDragging ? { color: 'red' } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </button>
                            &nbsp;
                            <button onClick={onImageRemoveAll}>Remove all images</button>
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img src={image?.data_url ? image?.data_url : image} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                  <button onClick={() => {
                                    setInitialValues({ ...initialValues, images: initialValues.images.filter(item => item != image) })
                                    onImageRemove(index)
                                  }}>Remove</button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>
                    </Grid>
                  </Grid>
                </DialogContent>
                {callingBackend ? <BeatLoader /> :
                  <DialogActions>
                    <Button
                      onClick={submit}
                      variant="contained"
                      color={
                        componentType === "create" ? "primary" : "secondary"
                      }
                      type="submit"
                      disabled={!isValid}
                    >
                      {componentType === "create" ? "create" : "update"}
                    </Button>
                    <Button
                      onClick={handleClose}
                      autoFocus
                      color="secondary"
                    >
                      cancel
                    </Button>
                  </DialogActions>
                }
              </Dialog>
            </Form>
          );
        }}
      </Formik>

    </div>
  );
}

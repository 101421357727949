import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import history from "../src/history";
import { makeStyles } from "@material-ui/core/styles";
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core/styles";

import Login from "../src/components/layouts/Login";
import NotFound from "../src/components/layouts/PageNotFound";
import PermissionDenied from "../src/components/layouts/PermissionDenied";
import "../src/index.css";
import purple from "@material-ui/core/colors/purple";
// Redux
import { Provider } from "react-redux";
import store from "./store/store";
import Loader from "./components/layouts/loader/Loader";
// protected route
import ProtectedRoute from "../src/components/common/ProtectedRoute";
import { routes } from "../src/routes";

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ddddd",
    },
    secondary: {
      main: purple[500],
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#FFFFFF",
        "&$error": {
          color: "#FFFFFF",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  self: {
    backgroundColor: "blue",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App(props) {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <Router history={history}>
          <div className={classes.root}>
            <ThemeProvider theme={darkTheme} />
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Switch>
                <Route path="/" exact component={Login}></Route>
                <Route path="/permission-denied" exact component={PermissionDenied} />
                {routes
                  .map(({ component, path }) => (
                    <ProtectedRoute component={component} exact path={path} />
                  ))}
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;

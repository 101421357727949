import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploading from 'react-images-uploading';
import BeatLoader from 'react-spinners/BeatLoader';

export default function ResponsiveDialog(props) {
    const [componentType, setComponentType] = useState("create");
    const [callingBackend, setCallingBackend] = useState(false);
    const { open, handleClose, loadData } = props;
    const [image, setImage] = useState([props.initialValues?.profilePic ? props.initialValues.profilePic : ""]);
    const [finsihed, setFinished] = useState(false);
    const [finalValues, setFinalValues] = useState(null);

    const [initialValues, setInitialValues] = useState({
        userName: props.initialValues?.userName || "",
        email: props.initialValues?.email || "",
        userType: props.initialValues?.userType || "",
        userStatus: props.initialValues?.userStatus || "",
        images: [props.initialValues?.profilePic] || []
    });
    let validationSchema = Yup.object().shape({
        userName: Yup.string()
            .required("userName is required!")
            .max(35, "Max length for the name is 35")
            .min(2, "Min length for the name is 2"),
        email: Yup.string()
            .email('Invalid email format')
            .required("userName is required!"),
    });

    useEffect(() => {
        if (props.initialValues) {
            setComponentType("update")
            setValues()
        }
    }, [props.initialValues]);

    const setValues = () => {
        setInitialValues({
            userName: props.initialValues?.userName || "",
            email: props.initialValues?.email || "",
            userType: props.initialValues?.userType || "",
            userStatus: props.initialValues?.userStatus || "",
            images: [props.initialValues?.profilePic] || []
        });
        setImage([props.initialValues?.profilePic]);
    }
    const [alert, setAlert] = useState({
        showAlert: false,
        severity: "success",
        message: "",
    });

    const handleInput = (e) => {
        setInitialValues((pre) => ({
            ...pre,
            [e.target.name]: e.target.value
        }));
    };

    const publishProduct = () => {
        if (finalValues.images?.length >= 1) {
            axios.put(`/user/${props.initialValues._id}`, { userType: finalValues?.userType, userStatus: finalValues?.userStatus, email: finalValues?.email, userName: finalValues?.userName, profilePic: finalValues?.images[0] }).then((res) => {
                setInitialValues({ "userName": "", "email": "", "userType": "", "userStatus": "", "images": [] })
                setFinalValues(null)
                setCallingBackend(false);
                handleClose();
                loadData();
                props.setSuccesMessage(true);
            })
                .catch((error) => {
                    props.setFailMessage(true);
                })
        } else {
            setCallingBackend(false);
        }
    }

    const submit = async (cb) => {
        let imagesToUpload = image.filter(img => img?.file)
        if (imagesToUpload?.length > 0) {
            let imageArray = [];
            for (let i = 0; i < imagesToUpload.length; i++) {
                setCallingBackend(true);
                const formData = new FormData();
                formData.append("file", imagesToUpload[i]?.file);
                formData.append("upload_preset", "eywnofyh");
                formData.append("cloud_name", "dtku2ojpc");

                fetch("https://api.cloudinary.com/v1_1/dtku2ojpc/image/upload", {
                    method: "post",
                    body: formData
                })
                    .then(res => res.json()
                        .then(formData => {
                            imageArray.push(formData.url);
                            if (i === imagesToUpload.length - 1) {
                                setFinalValues({ ...initialValues, images: [...initialValues.images, ...imageArray] });
                            }
                        }))
                    .catch(err => {
                        console.log(err);
                    })
            }
        }
        else {
            setFinalValues({ ...initialValues })
        }
    };
    useEffect(() => {
        if (finalValues) {
            setCallingBackend(false);
            setFinished(true);
        }
    }, [finalValues]);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        green: {
            backgroundColor: "green",
            color: "white",
        },
        red: {
            backgroundColor: "red",
            color: "white",
        },
        yellow: {
            backgroundColor: "orange",
            color: "white",
        },
        textCenter: {
            textAlign: "center",
        },
        loading: {
            marginRight: "5rem",
        },
    }));

    const maxNumber = 69;
    const onChange = (imageList) => {
        setImage(imageList);
    };

    useEffect(() => {
        if (finsihed)
            publishProduct()
        setFinished(false)

    }, [finsihed]);

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ errors, isValid, dirty, handleChange, values, setFieldValue, getFieldProps }) => {
                    return (
                        <Form>
                            <Dialog
                                fullWidth="100%"
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    {componentType === "create" && (
                                        "Add a new listing "
                                    )}
                                    {componentType === "update" && (
                                        "Edit a listing "
                                    )}
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2} >
                                        <Grid item md={6}>
                                            <Grid>
                                                <Field
                                                    {...getFieldProps('userName')}
                                                    onChange={handleInput}
                                                    name="userName"
                                                    label="userName"
                                                    component={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                ></Field>
                                            </Grid>
                                            <Grid style={{ marginTop: "1rem" }}>
                                                <Field
                                                    onChange={handleInput}
                                                    name="email"
                                                    label="email"
                                                    component={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                ></Field>
                                            </Grid>
                                            <Grid style={{ marginTop: "1rem" }}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="demo-simple-select-label">
                                                        User Type
                                                    </InputLabel>
                                                    <Select
                                                        onChange={handleInput}
                                                        value={initialValues.userType ? initialValues.userType : ""}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        label="User Type"
                                                        name="userType"
                                                    >
                                                        <MenuItem value={"seller"}>seller</MenuItem>
                                                        <MenuItem value={"admin"}>admin</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid style={{ marginTop: "1rem" }}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="demo-simple-select-label">
                                                        User Status
                                                    </InputLabel>
                                                    <Select
                                                        onChange={handleInput}
                                                        value={initialValues.userStatus ? initialValues.userStatus : ""}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        label="User Status"
                                                        name="userStatus"
                                                    >
                                                        <MenuItem value={"active"}>active</MenuItem>
                                                        <MenuItem value={"inactive"}>inactive</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6}>
                                            <ImageUploading
                                                multiple
                                                value={image}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                name="images"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        <button
                                                            style={isDragging ? { color: 'red' } : undefined}
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                        >
                                                            Click or Drop here
                                                        </button>
                                                        &nbsp;
                                                        <button onClick={onImageRemoveAll}>Remove all images</button>
                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item">
                                                                <img src={image?.data_url ? image?.data_url : image} alt="" width="100" />
                                                                <div className="image-item__btn-wrapper">
                                                                    <button onClick={() => {
                                                                        setInitialValues({ ...initialValues, images: initialValues.images.filter(item => item != image) })
                                                                        onImageRemove(index)
                                                                    }}>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                {callingBackend ? <BeatLoader /> :
                                    <DialogActions>
                                        <Button
                                            onClick={submit}
                                            variant="contained"
                                            color={
                                                componentType === "create" ? "primary" : "secondary"
                                            }
                                            type="submit"
                                            disabled={!isValid}
                                        >
                                            {componentType === "create" ? "create" : "update"}
                                        </Button>
                                        <Button
                                            onClick={handleClose}
                                            autoFocus
                                            color="secondary"
                                        >
                                            cancel
                                        </Button>
                                    </DialogActions>
                                }

                            </Dialog>
                        </Form>
                    );
                }}
            </Formik>

        </div>
    );
}

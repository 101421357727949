import React from "react";
import Grid from "@material-ui/core/Grid";
import HeaderAndLayout from '../layouts/HeaderAndLayout';
import PieChart from "../reports/PieChart";
import BarChart from "../reports/BarChart";
import IntegerViews from "../reports/integerViews";
import ListingClicksCharts from '../reports/ListingClicksChart';
import ListingClicksByUsers from '../reports/ListingClicksByUsers';
import BarChartListing from '../reports/BarChartListing';

export default function Dashboard() {
    return (
        <div className="container" style={{ marginTop: "2rem" }}>
            <HeaderAndLayout activeItem={"dashboard"} />
            <Grid container spacing={3} justify="center" m={2}>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                >
                    <BarChart />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                >
                    <PieChart />
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="center" m={2}>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                >
                    <IntegerViews />
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="center" m={2} style={{marginTop:"5rem"}}>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                >
                    <BarChartListing />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                >
                    <ListingClicksByUsers />
                </Grid>
            </Grid>
        </div>
    );
}

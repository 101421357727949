import Register from "../src/components/layouts/Register";
import Account from '../src/components/Account/Account';
import "../src/index.css";
import Categories from "./components/Categories/Categories";
import Dashboard from "./components/Dashboard/dashboard";
import Products from "./components/Product/product";
import Users from "./components/user/users";
import Reports from "./components/reports/Reports";


export const routes = [
  {
    component:Dashboard,
    path:"/dashboard"
  },
  {
    component:Products,
    path:"/Listing"
  },
  {
    component:Categories,
    path:"/categories"
  },
  {
    component:Register,
    path:"/register"
  },
  {
    component:Account,
    path:"/account"
  },
  {
    component:Users,
    path:"/users"
  },
  {
    component:Reports,
    path:"/reports"
  }
];


import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploading from 'react-images-uploading';
import BeatLoader from 'react-spinners/BeatLoader';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export default function ResponsiveDialog(props) {
    const [callingBackend, setCallingBackend] = useState(false);
    const { open, handleClose } = props;
    const [finsihed, setFinished] = useState(false);
    const [finalValues, setFinalValues] = useState(null);
    const [initialValues, setInitialValues] = useState({
        newPassword: "",
        newReTryPassword: "",
    });
    const [value, setValue] = React.useState({
        showPassword: false,
    });

    let validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required("Password is required!")
            .max(12, "Max length for the Password is 12")
            .min(6, "Min length for the Password is 6"),
        newReTryPassword: Yup.string()
            .required("Confirm Password is required!")
            .max(12, "Max length for the confirm Password is 12")
            .min(6, "Min length for the confirm Password is 6"),
    });

    const [alert, setAlert] = useState({
        showAlert: false,
        severity: "success",
        message: "",
    });

    const handleInput = (e) => {
        setInitialValues((pre) => ({
            ...pre,
            [e.target.name]: e.target.value
        }));
    };

    const handleClickShowPassword = (value) => {
        setValue({ ...value, showPassword: !value.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const resetPasswod = () => {
        axios.put(`/user/${window.sessionStorage.getItem("id")}`, { password: finalValues.newPassword }).then((res) => {
            setInitialValues({ "newPassword": "", "newReTryPassword": "" })
            setCallingBackend(false);
            handleClose();
            props.setSuccesMessage(true);
        })
            .catch((error) => {
                props.setFailMessage(true);
            })
    }

    const submit = async (cb) => {
        setFinalValues({ ...initialValues })
    };
    useEffect(() => {
        if (finalValues) {
            setCallingBackend(true);
            setFinished(true);
        }
    }, [finalValues]);

    useEffect(() => {
        if (finsihed) {
            if (finalValues?.newPassword === finalValues?.newReTryPassword) {
                resetPasswod()
                setFinished(false)
            } else {
                props.setFailPasswordMatch(true);
                setCallingBackend(false);
                setFinished(false)
            }
        }
    }, [finsihed]);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        green: {
            backgroundColor: "green",
            color: "white",
        },
        red: {
            backgroundColor: "red",
            color: "white",
        },
        yellow: {
            backgroundColor: "orange",
            color: "white",
        },
        textCenter: {
            textAlign: "center",
        },
        loading: {
            marginRight: "5rem",
        },
    }));

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ errors, isValid, dirty, handleChange, values, setFieldValue, getFieldProps }) => {
                    return (
                        <Form>
                            <Dialog
                                fullWidth="100%"
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    Reset password
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2} >
                                        <Grid item md={6}>
                                            <Grid>
                                                <Field
                                                    //{...getFieldProps('newPassword')}
                                                    onChange={handleInput}
                                                    name="newPassword"
                                                    label="New Password"
                                                    component={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="dense"
                                                    type={value?.showPassword ? "text" : "password"}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        handleClickShowPassword(value)
                                                                    }
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {value?.showPassword ? (
                                                                        <Visibility />
                                                                    ) : (
                                                                        <VisibilityOff />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                ></Field>
                                            </Grid>
                                            <Grid style={{ marginTop: "1rem" }}>
                                                <Field
                                                    onChange={handleInput}
                                                    name="newReTryPassword"
                                                    label="Confirm Password"
                                                    component={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="dense"
                                                    type={value?.showPassword ? "text" : "password"}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        handleClickShowPassword(value)
                                                                    }
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {value?.showPassword ? (
                                                                        <Visibility />
                                                                    ) : (
                                                                        <VisibilityOff />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                ></Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                {callingBackend ? <BeatLoader /> :
                                    <DialogActions>
                                        <Button
                                            onClick={submit}
                                            variant="contained"
                                            color={"primary"}
                                            type="submit"
                                            disabled={!isValid}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            onClick={handleClose}
                                            autoFocus
                                            color="secondary"
                                        >
                                            cancel
                                        </Button>
                                    </DialogActions>
                                }
                            </Dialog>
                        </Form>
                    );
                }}
            </Formik>

        </div>
    );
}

import { LOGIN, LOGOUT, TOGGLE, REGISTER } from "./types";
import axios from "axios";
import { routes } from "../../routes";
import  jwt  from "jsonwebtoken";
// import  browserHistory  from '../../history'
export const login = (token) => (dispatch) => {
  try {
    //let permittedRoutes;
   /*  if (role === "super") {
      // eslint-disable-next-line
      permittedRoutes = routes.filter((route) => {
        const children = route.children;

        const intersection = children.filter((element) => !element.isHidden);
        if (intersection.length) {
          route.children = intersection;
          return route;
        }
      });
    } else {
      // eslint-disable-next-line
      permittedRoutes = routes.filter((route) => {
        const children = route.children;

        const intersection = children.filter(
          (element) => permissions.includes(element.path) && !element.isHidden
        );
        if (intersection.length) {
          route.children = intersection;
          return route;
        }
      });
    } */
    let {firstName,userType,id} = jwt.decode(token);

    // save to the session storage
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("name", firstName);
    sessionStorage.setItem("role", userType);
    /* sessionStorage.setItem("routes", JSON.stringify(permittedRoutes)); */
    sessionStorage.setItem("id", id);

    axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
      "token"
    )}`;

    dispatch({
      type: LOGIN,
      payload: { token, firstName, userType,id },
    });
    // browserHistory.push(permittedRoutes[0].children[0].path)
  } catch (error) {}
};

export const register = (name, role, token, permissions, id) =>(dispatch) =>{
  try {
    let permittedRoutes;
    if (role === "super") {
      // eslint-disable-next-line
      permittedRoutes = routes.filter((route) => {
        const children = route.children;

        const intersection = children.filter((element) => !element.isHidden);
        if (intersection.length) {
          route.children = intersection;
          return route;
        }
      });
    } else {
      // eslint-disable-next-line
      permittedRoutes = routes.filter((route) => {
        const children = route.children;

        const intersection = children.filter(
          (element) => permissions.includes(element.path) && !element.isHidden
        );
        if (intersection.length) {
          route.children = intersection;
          return route;
        }
      });
    }

    // save to the session storage
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("name", name);
    sessionStorage.setItem("role", role);
    sessionStorage.setItem("routes", JSON.stringify(permittedRoutes));
    sessionStorage.setItem("id", id);

    axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
      "token"
    )}`;

    dispatch({
      type: REGISTER,
      payload: { token, name, role, routes: permittedRoutes, id },
    });
    // browserHistory.push(permittedRoutes[0].children[0].path)
  } catch (error) {}
};


export const logout = () => (dispatch) => {
  // remove items from session storage
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("name");
  sessionStorage.removeItem("role");
  sessionStorage.removeItem("routes");
  sessionStorage.removeItem("id");

  axios.defaults.headers.common.Authorization = null;

  dispatch({
    type: LOGOUT,
  });
};

export const toggleDrawer = () => (dispatch) => {
  dispatch({
    type: TOGGLE,
  });
};

//reset password

export const resetPass = () => (dispatch) => {
  dispatch({});
};

import { LOGIN, LOGOUT, TOGGLE } from "../actions/types";

const initialState = {
  token: sessionStorage.getItem("token"),
  role: sessionStorage.getItem("role"),
  name: sessionStorage.getItem("name"),
  id:sessionStorage.getItem("id"),
  routes: JSON.parse(sessionStorage.getItem("routes")),
  isDrawerOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action.payload };

    case LOGOUT:
      return initialState;

    case TOGGLE:
      return { ...state, isDrawerOpen: !state.isDrawerOpen };

    default:
      return state;
  }
}
